@mixin font-family($font-name, $font-weight, $font-style, $font-url, $font-file) {
  @font-face {
    font-family: '#{$font-name}';
    src: url('#{$font-url}#{$font-file}.ttf');
    // src: url('#{$font-url}#{$font-file}.eot?#iefix') format('embedded-opentype'), url('#{$font-url}#{$font-file}.woff') format('woff'), url('#{$font-url}#{$font-file}.ttf') format('truetype'), url('#{$font-url}#{$font-file}.svg##{$font-name}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}


@include font-family('OpenSans', 300, normal, '/assets/fonts/open-sans/', 'OpenSans-Light');
@include font-family('OpenSans', 400, normal, '/assets/fonts/open-sans/', 'OpenSans-Regular');
@include font-family('OpenSans', 500, normal, '/assets/fonts/open-sans/', 'OpenSans-Medium');
@include font-family('OpenSans', 600, normal, '/assets/fonts/open-sans/', 'OpenSans-Semibold');
@include font-family('OpenSans', 700, normal, '/assets/fonts/open-sans/', 'OpenSans-Bold');
