.icon {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;

  &.rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  &.icon-hitachi-logo {
    background-image: url("/assets/i/Hitachi-logo.svg");
  }

  &.icon-connect-rect {
    background-image: url("/assets/i/connect-rect.svg");
  }

  &.icon-check-circle-green {
    background-image: url("/assets/i/check-circle-green.svg");
  }

  &.icon-cancel-circle-red {
    background-image: url("/assets/i/cancel-circle-red.svg");
  }

  &.icon-arrow-up {
    background-image: url("/assets/i/arrow-up.svg");
  }

  &.icon-arrow-up-blue {
    background-image: url("/assets/i/arrow-up-blue.svg");
  }

  &.icon-hamburger-icon {
    background-image: url("/assets/i/hamburg-icon.svg");
    width: 25px;
    height: 16px;
  }

  &.icon-hamburger {
    background-image: url("/assets/i/hamburger.svg");
  }

  &.icon-hamburger-white {
    background-image: url("/assets/i/hamburger-white.svg");
  }

  &.icon-zoom-default {
    background-image: url("/assets/i/zoom-default.svg");
  }

  &.icon-hoz-line {
    background-image: url("/assets/i/hoz-line.svg");
    width: 38px;
    height: 20px;
  }

  &.icon-area {
    background-image: url("/assets/i/area.svg");
  }

  &.icon-policy {
    background-image: url("/assets/i/policy.svg");

    &.active {
      background-image: url("/assets/i/policy-active.svg");
    }
  }

  &.icon-dots {
    background-image: url("/assets/i/icon-dots.svg");
  }

  &.icon-dots-gray {
    background-image: url("/assets/i/icon-dots-gray.svg");
  }

  &.icon-chevron-down-gray {
    background-image: url("/assets/i/chevron-down-gray.svg")
  }

  &.icon-close-rounded {
    background-image: url("/assets/i/close-rounded.svg")
  }

  &.icon-close-gray {
    background-image: url("/assets/i/close-gray.png")
  }

  &.icon-eye {
    background-image: url("/assets/i/icon-eye.svg");
    background-size: 18px 18px;
  }

  &.icon-search {
    background-image: url("/assets/i/icon-search.svg")
  }

  &.icon-drag-indicator-white {
    width: 8px;
    height: 16px;
    background-image: url("/assets/i/drag-indicator-white.svg");
  }

  &.icon-drag-indicator-grey {
    width: 8px;
    height: 16px;
    background-image: url("/assets/i/drag-indicator-grey.svg");
  }

  &.icon-drag-indicator {
    width: 8px;
    height: 16px;
    background-image: url("/assets/i/drag-indicator.svg")
  }

  &.icon-save-gray-border {
    background-image: url("/assets/i/icon-save-gray-border.svg");
  }

  &.icon-add-blue {
    background-image: url("/assets/i/add-blue.svg");
  }

  &.icon-edit {
    background-image: url("/assets/i/edit-icon.svg");
    background-size: 16px 16px;
    height: 16px;
    width: 16px;
  }

  &.icon-setting {
    background-image: url("/assets/i/setting.svg");
  }

  &.icon-setting-white {
    background-image: url("/assets/i/setting-white.svg");
  }

  &.icon-project-entities {
    background-image: url("/assets/i/project-entities.svg");
  }

  &.icon-kernel-parametrisation {
    background-image: url("/assets/i/kernel-parametrisation.svg");
  }

  &.icon-task-criterias {
    background-image: url("/assets/i/task-criterias.svg");
  }

  &.icon-filter-border {
    background-image: url("/assets/i/filter-gray.svg");

    &.checked {
      background-image: url("/assets/i/filter-icon-border.svg");
    }

    &:not(.checked) {
      background-size: 50%;
    }
  }

  &.icon-project-modelling {
    background-image: url("/assets/i/project-modelling.svg");
  }

  &.icon-members-privileges {
    background-image: url("/assets/i/members-privileges.svg");
  }

  &.icon-manage-kernel {
    background-image: url("/assets/i/manage-kernel.svg");
  }

  &.icon-add-new-gray {
    background-image: url("/assets/i/add-new-gray.svg");
  }

  &.icon-add-gray {
    background-image: url("/assets/i/add-gray.svg");
  }

  &.icon-add-new {
    background-image: url("/assets/i/add-new.svg");
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-right: 16px;
  }

  &.icon-info {
    background-image: url("/assets/i/info.svg");
    background-size: 18px 18px;
  }

  &.icon-select-policies {
    background-image: url("/assets/i/policies-Icon.svg");
    width: 150px;
    height: 150px;
  }

  &.icon-info-white {
    background-image: url("/assets/i/info-white.svg");
    background-size: 20px 20px;
    width: 16px;
    height: 16px;
  }

  &.icon-install-base {
    background-image: url("/assets/i/icon-install-base.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  &.icon-op-center-w {
    background-image: url("/assets/i/icon-op-center-w.svg");
    background-size: 16px 16px !important;
    width: 16px;
    height: 16px;
  }

  &.icon-op-center {
    background-image: url("/assets/i/icon-op-center.svg");
    background-size: 16px 16px !important;
    width: 16px;
    height: 16px;
  }

  &.icon-projects-list {
    background-image: url("/assets/i/icon-projects-list.svg");
    background-size: 20px 20px;
    width: 16px;
    height: 16px;
  }

  &.icon-checkbox {
    background-image: url("/assets/i/checkbox-unchecked.svg");
    width: 24px;
    height: 24px;

    &.checked {
      background-image: url("/assets/i/checkbox-checked.svg");
    }

    &.indeterminate {
      background-image: url("/assets/i/checkbox-indeterminate.svg");
    }
  }
  &.icon-checkbox-black {
    background-image: url("/assets/i/checkbox-black.svg");
    &.checked {
      background-image: url("/assets/i/checkbox-checked.svg");
    }
  }

  &.icon-switchbox {
    background-image: url("/assets/i/close.svg");

    &.checked {
      background-image: url("/assets/i/CheckMark.svg");
    }
  }

  &.icon-role {
    background-image: url("/assets/i/member.svg");
  }

  &.icon-role-black {
    background-image: url("/assets/i/member-black.svg");
  }

  &.icon-privilege.enabled {
    background-image: url("/assets/i/checked.svg");
  }

  &.icon-privilege-green.enabled {
    background-image: url("/assets/i/checked-green.svg");
  }

  &.icon-execution-team {
    background-image: url("/assets/i/execution-team.svg");
    width: 100px;
    height: 100px;
  }

  &.icon-back {
    background-image: url("/assets/i/back.svg");
    background-size: 24px 24px;
    width: 20px;
    height: 20px;
  }

  &.icon-next-page {
    background-image: url("/assets/i/back.svg");
    transform: rotateY(180deg);
  }

  &.icon-avatar {
    background-image: url("/assets/i/avatar.svg");
  }

  &.icon-overdue {
    background-image: url("/assets/i/icon-overdue.svg");
    width: 24px;
    height: 24px;
  }

  &.icon-filter {
    background-image: url("/assets/i/filter.svg");

    &.checked {
      background-image: url("/assets/i/filter-checked.svg");
    }
  }

  &.icon-remove {
    background-image: url("/assets/i/remove.svg");
  }

  &.icon-del {
    background: url('/assets/i/icon-del@2x.png') center center no-repeat;
    background-size: 16px;
  }

  &.icon-delete-red {
    background: url('/assets/i/icon-delete-red.svg');
    background-repeat: no-repeat;
  }

  &.icon-remove-user {
    background-image: url("/assets/i/remove-user.svg");
    width: 16px;
    height: 16px;
  }

  &.icon-remove-marker {
    background-image: url("/assets/i/remove-maker.svg");
  }

  &.icon-zoom {
    background-image: url("/assets/i/zoom-in.svg");
  }

  &.icon-file {
    background-image: url("/assets/i/file.svg");
  }

  &.icon-file-doc {
    background-image: url("/assets/i/document-icon@2x.png");
  }

  &.icon-add-white {
    background-image: url("/assets/i/add-white.svg");
  }

  &.icon-add-border {
    background-image: url("/assets/i/add-border.svg");
  }

  &.icon-reset {
    background-image: url("/assets/i/reset.svg");
  }

  &.icon-close {
    background-image: url("/assets/i/close.svg");
  }

  &.icon-close-black {
    background-image: url("/assets/i/close-icon.svg");
  }

  &.icon-notes {
    background-image: url("/assets/i/notes.svg");
  }

  &.icon-shield {
    background-image: url("/assets/i/shield.svg");
  }

  &.icon-calendar {
    background-image: url("/assets/i/calendar.svg");
  }

  &.icon-checkmark-circle {
    background-image: url("/assets/i/checkmark-circle.svg");
  }

  &.icon-white-close {
    background-image: url("/assets/i/close-white.svg");
  }

  &.icon-white-edit {
    background-image: url("/assets/i/edit-white-icon.svg");
  }

  &.icon-swms {
    background-image: url("/assets/i/swms-icon.svg");
  }

  &.icon-policy-menu {
    background-image: url("/assets/i/policy-menu-icon.svg");
  }

  &.icon-view {
    background-image: url("/assets/i/eye-icon.svg");
  }

  &.icon-document {
    background-image: url("/assets/i/document-icon.svg");
  }

  &.icon-download {
    background-image: url("/assets/i/upload-icon.svg");
    transform: rotate(90deg);
  }

  &.icon-refresh {
    background-image: url("/assets/i/refresh-icon.svg");
  }

  &.icon-up {
    background-image: url("/assets/i/up-icon.svg");
    transform: rotate(180deg);
    transition: all .2s;
    top: 5px;

    &.down {
      transform: rotate(0deg);
      top: 5px;
    }
  }

  &.icon-chevron-down-dark {
    background-image: url("/assets/i/chevron-down-dark.svg");
    background-size: 16px 16px;
    height: 16px;
    width: 16px;
  }

  &.icon-chevron-up {
    background-image: url("/assets/i/chevron-up.svg");
    height: 24px;
    width: 24px;
  }

  &.icon-chevron-up-policy {
    background-image: url("/assets/i/chevron-up-policy.svg");
  }

  &.icon-prev {
    background-image: url("/assets/i/icon-prev.svg");
    background-size: 5px 8px;
    width: 5px;
    height: 8px;
  }

  &.icon-next {
    background-image: url("/assets/i/icon-next.svg");
    background-size: 5px 8px;
    width: 5px;
    height: 8px;
  }

  &.icon-add-area {
    background-image: url("/assets/i/icon-add-area.svg");
    background-size: 16px 20px;
    width: 16px;
    height: 20px;
  }

  &.icon-remove-area {
    background-image: url("/assets/i/icon-remove-area.svg");
    background-size: 14px 16px;
    width: 14px;
    height: 16px;
  }

  &.icon-duplicate-area {
    background-image: url("/assets/i/icon-duplicate-area.svg");
    background-size: 16px 12px;
    width: 16px;
    height: 12px;
  }

  &.icon-edit-black {
    background-image: url("/assets/i/icon-edit-black.svg");
    background-size: 22px 22px;
    width: 22px;
    height: 22px;
  }

  &.icon-edit-white {
    background-image: url("/assets/i/icon-edit-white.svg");
    background-size: 22px 22px;
    width: 22px;
    height: 22px;
  }

  &.icon-edit-area {
    background-image: url("/assets/i/icon-edit-area.svg");
    background-size: 16px 12px;
    width: 16px;
    height: 12px;
  }

  &.icon-area-up {
    background-image: url("/assets/i/icon-area-up.svg");
    background-size: 8px 6px;
    width: 8px;
    height: 6px;
    top: 5px;
  }

  &.icon-area-down {
    background-image: url("/assets/i/icon-area-down.svg");
    background-size: 8px 6px;
    width: 8px;
    height: 6px;
    top: 5px;
  }

  &.icon-decision-cancel {
    background-image: url("/assets/i/icon-decision-cancel.svg");
    background-size: 20px 20px;
    width: 16px;
    height: 16px;
  }

  &.icon-decision-plan,
  &.icon-notification,
  &.icon-decision-undecided {
    background-image: url("/assets/i/icon-decision-plan.svg");
    background-size: 14px 16px;
    width: 14px;
    height: 16px;
  }

  &.icon-decision-alert {
    background-image: url("/assets/i/icon-decision-alert.svg");
    background-size: 20px 24px;
    width: 12px;
    height: 16px;
  }

  &.icon-decision-postpone {
    background-image: url("/assets/i/icon-decision-postpone.svg");
    background-size: 14px 15px;
    width: 14px;
    height: 15px;
  }

  &.icon-menu-system {
    background-image: url("/assets/i/reset-white.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  &.icon-menu-policy {
    background-image: url("/assets/i/reset-white.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  &.icon-dropdown {
    background-image: url("/assets/i/icon-dropdown.svg");
    background-size: 18px 14px;
    width: 8px;
    height: 15px;
    transition: all .2s;
    transform: rotate(180deg);

    &.up {
      transform: rotate(0deg);
    }
  }

  &.icon-menu-ie {
    background-image: url("/assets/i/icon-ie.svg");
    background-size: 20px 20px;
    width: 16px;
    height: 16px;
  }

  &.icon-menu-home {
    background-image: url("/assets/i/icon-home.svg");
    background-size: 20px 20px;
    width: 16px;
    height: 16px;
  }

  &.icon-menu-kb {
    background-image: url("/assets/i/icon-kb.svg");
    background-size: 20px 20px;
    width: 16px;
    height: 16px;
  }

  &.icon-audit-log {
    background-image: url("/assets/i/log.svg");
    background-size: 20px 20px;
    width: 16px;
    height: 16px;
    transform: rotate(-360deg);
  }

  &.icon-smart-phone {
    background-image: url("/assets/i/smartPhone.png");
    background-size: 20px 20px !important;
    width: 16px !important;
    height: 16px !important;
  }

  &.icon-menu-notify {
    background-image: url("/assets/i/icon-notify.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  &.icon-menu-pref-monitor {
    background-image: url("/assets/i/icon-pref-monitor.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  &.icon-remove-plan {
    background-image: url("/assets/i/icon-remove-plan.svg");
    background-size: 14px 16px;
    width: 14px;
    height: 16px;
    margin-right: 10px;
  }

  &.icon-remove-plan-default {
    background-image: url("/assets/i/icon-remove-plan.svg");
    background-size: 14px 16px;
    width: 14px;
    height: 16px;
    margin-right: 10px;
  }

  &.icon-remove-plan-white {
    background-image: url("/assets/i/icon-remove-plan-hover.svg");
    background-size: 14px 16px;
    width: 14px;
    height: 16px;
    margin-right: 10px;
  }

  &.icon-pen {
    background-image: url("/assets/i/icon-pen.svg");
    background-size: 18px 18px;
    width: 14px;
    height: 14px;
  }

  &.icon-export {
    background-image: url("/assets/i/icon-export.svg");
    background-size: 22px 26px;
    width: 14px;
    height: 18px;
    margin: 7px;
  }

  &.icon-bell {
    background-image: url("/assets/i/icon-bell.svg");
    background-size: 20px 24px;
    width: 20px;
    height: 24px;
  }

  &.icon-exclamation {
    background-image: url("/assets/i/exclamation.svg");
    filter: brightness(0) invert(1);
    background-size: 24px 22px;
    width: 24px;
    height: 16px;

    &-low {
      background-image: url("/assets/i/exclamation-low.svg");
      filter: none;
    }

    &-medium {
      background-image: url("/assets/i/exclamation-medium.svg");
      filter: none;
    }

    &-high {
      background-image: url("/assets/i/exclamation-high.svg");
      filter: none;
    }
  }

  &.icon-table-download {
    background-image: url("/assets/i/icon-table-download.svg");
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
  }

  &.square-menu-icon {
    background-image: url("/assets/i/square-menu-icon.svg");
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }

  &.square-menu-white-icon {
    background-image: url("/assets/i/square-menu-white-icon.svg");
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }

  &.icon-report {
    background-image: url("/assets/i/icon-report.svg");
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  &.icon-mute {
    background-image: url("/assets/i/icon-mute.svg");
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
  }

  &.icon-unmute {
    background-image: url("/assets/i/icon-unmute.svg");
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
  }

  &.icon-warning {
    background-image: url("/assets/i/icon-warning.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  &.icon-offline {
    background-image: url("/assets/i/icon-offline.svg");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
  }

  &.icon-next-dark {
    background-image: url("/assets/i/icon-next-dark.svg");
    background-size: 6px 8px;
    width: 6px;
    height: 8px;
  }

  &.icon-next-white {
    background-image: url("/assets/i/icon-next-white.svg");
    background-size: 6px 8px;
    width: 6px;
    height: 8px;
  }

  .icon-project-entities {
    background-image: url("/assets/i/project-entities-white.svg");
  }

  .icon-kernel-parametrisation {
    background-image: url("/assets/i/kernel-parametrisation-white.svg");
  }

  .icon-task-criterias {
    background-image: url("/assets/i/task-criterias-white.svg");
  }

  .icon-project-modelling {
    background-image: url("/assets/i/project-modelling-white.svg");
  }

  .icon-members-privileges {
    background-image: url("/assets/i/members-privileges-white.svg");
  }

  .icon-manage-kernel {
    background-image: url("/assets/i/manage-kernel-white.svg");
  }

  .icon-report {
    background-image: url("/assets/i/icon-report-white.svg");
  }

  &.icon-delete {
    background-image: url("/assets/i/delete_outline_black_24dp.svg");
    width: 20px;
  }

  &.icon-copy {
    background-image: url("/assets/i/content_copy_black_24dp.svg");
    width: 16px;
  }

  &.icon-add-outline {
    background-image: url("/assets/i/add_circle_outline_black_24dp.svg");
    width: 16px;
  }

  &.icon-duplicate-equip {
    background-image: url("/assets/i/Inject.S [D].svg");
  }

  &.icon-risk-matrix {
    background-image: url("/assets/i/Risk Matrix.svg");
  }

  &.icon-table-list {
    background-image: url("/assets/i/table-list-view.svg");
  }

  &.icon-riskmatrix-fullscreen {
    background-image: url("/assets/i/Fullscreen.S [D].svg");
  }

  //HOME-PAGE icons
  &.icon-home {
    background-image: url("/assets/homepage/Home.svg");
  }

  &.icon-home-w {
    background-image: url("/assets/homepage/Home_W.svg");
  }

  &.icon-installedbase {
    background-image: url("/assets/homepage/Installed_base.svg");
  }

  &.icon-installedbase-w {
    background-image: url("/assets/homepage/Installed_base_W.svg");
  }

  &.icon-policies {
    background-image: url("/assets/homepage/Policies.svg");
  }

  &.icon-policies-w {
    background-image: url("/assets/homepage/Policies_W.svg");
  }

  &.icon-auditlog {
    background-image: url("/assets/homepage/Audit_log.svg");
  }

  &.icon-auditlog-w {
    background-image: url("/assets/homepage/Audit_log_W.svg");
  }

  &.icon-configuration {
    background-image: url("/assets/homepage/Configuration.svg");
  }

  &.icon-configuration-w {
    background-image: url("/assets/homepage/Configuration_W.svg");
  }

  &.icon-dashboard {
    background-image: url("/assets/homepage/Dashboard.svg");
  }

  &.icon-dashboard-w {
    background-image: url("/assets/homepage/Dashboard_W.svg");
  }

  &.icon-documentation {
    background-image: url("/assets/homepage/Documentation.svg");
  }

  &.icon-documentation-w {
    background-image: url("/assets/homepage/Documentation_W.svg");
  }

  &.icon-projects {
    background-image: url("/assets/homepage/Projects.svg");
  }

  &.icon-projects-w {
    background-image: url("/assets/homepage/Projects_W.svg");
  }

  &.icon-switchingoperations {
    background-image: url("/assets/homepage/Switching_operations.svg");
  }

  &.icon-switchingoperations-w {
    background-image: url("/assets/homepage/Switching_operations_W.svg");
  }

  &.icon-tablelist {
    background-image: url("/assets/homepage/Tablelist.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-tablelist-w {
    background-image: url("/assets/homepage/Tablelist_W.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-maintenance {
    background-image: url("/assets/homepage/Maintenance.svg");
  }

  &.icon-maintenance-w {
    background-image: url("/assets/homepage/Maintenance_W.svg");
  }

  &.icon-select-substation {
    background-image: url("/assets/homepage/Select_substation.svg");
    width: 150px;
    height: 150px;
  }
  &.icon-external-link {
    background-image: url("/assets/homepage/external_link.svg");
    width: 12px;
    height: 12px;
  }
  
  //Grid modelling icons
  &.icon-zoomin {
    background-image: url("/assets/homepage/Zoom_In.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-zoomin_W {
    background-image: url("/assets/homepage/Zoom_In_W.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-zoomout {
    background-image: url("/assets/homepage/Zoom_out.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-zoomout_W {
    background-image: url("/assets/homepage/Zoom_out_W.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-position_W {
    background-image: url("/assets/homepage/Position_W.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-position {
    background-image: url("/assets/homepage/Position.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }

  &.icon-logout {
    background-image: url("/assets/homepage/log-out.svg");
  }

  &.icon-compact-menu {
    background-image: url("/assets/homepage/compact-menu.svg");
  }

  &.icon-expand-up {
    background-image: url("/assets/i/expand-up.svg");
    width: 16px;
    height: 10px;
    background-size: 45px 45px;
  }

  &.icon-collapse-down {
    background-image: url("/assets/i/collapse-down.svg");
    width: 16px;
    height: 10px;
    background-size: 45px 45px;
  }
  &.icon-down-black {
    background-image: url("/assets/i/down-black.svg");
    width: 60px;
    height: 10px;
    background-size: 30px 30px;
  }
  &.icon-event-timeline {
    background-image: url("/assets/i/Event-timeline.svg");
    width: 16px;
    height: 16px;
    background-size: 36px 36px;
  }
  &.icon-document--view {
    background-image: url("/assets/i/Document--view.svg");
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
  }
  &.icon-document--download {
    background-image: url("/assets/i/Document--download.svg");
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
  }
}

.active {
  .icon-hoz-line {
    background-image: url("/assets/i/hamburg-icon-active.svg");
  }

  .icon-remove-plan {
    background-image: url("/assets/i/icon-remove-plan-hover.svg");
  }
}