@mixin filter-container {
  width: 180px;
  right: -11px;
  top: -12px;
  z-index: 1000;
  background-color: #3D3D3D;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 0 0 16px 16px;
  border-radius: 5px;
  user-select: none;
  .header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #686868;
    height: 41px;
    .icon {
      width: 40px;
      height: 40px;
      background-size: 40%;
      cursor: pointer;
    }
  }
  .filter-option {
    height: 30px;
    display: flex;
    align-items: center;
    .icon {
      width: 16px;
      height: 16px;
    }
    span {
      padding-left: 5px;
      font-size: 13px;
    }
  }
  &.filter-hide {
    background-color: transparent;
    // max-width: 100%;
    padding: 0 0 0 16px;
    width: 70px;
    .header {
      border-bottom: none;
      span {
        display: none;
      }
    }
    .filter-option {
      display: none;
    }
  }
}
.project-listing, .assets-listing, .projects-selection-listing, .inst-listing, .listing {
  display: block;
  padding: 16px;
  .ngx-datatable {
    .datatable-header {
      border-bottom: 1px solid $white-grey;
      .datatable-header-cell {
        // .sort-btn {
        //   width: 16px;
        //   height: 16px;
        //   &.sort-asc {
        //     background: url('/assets/i/sort-down.svg') no-repeat;
        //     background-size: 8px 8px;
        //     margin-left: 16px;
        //   }
        //   &.sort-desc {
        //     background: url('/assets/i/sort-down.svg') no-repeat;
        //     background-size: 8px 8px;
        //     margin-left: 16px;
        //     transform: rotate(180deg);
        //   }
        // }
        .datatable-header-cell-label {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: $lightBlack;
          padding-left: 24px;
        }
      }
      .datatable-header-cell {
        margin-bottom: 10px;
        font-size: 12px;
        .sort-btn {
          width: 16px;
          height: 16px;
          background: url('/assets/i/sort-down.svg') no-repeat;
            background-size: 16px 16px;
            margin-left: 16px;
          &.sort-desc {
            background: url('/assets/i/sort-down.svg') no-repeat;
            background-size: 16px 16px;
            transform: rotate(180deg);
          }
        }
      }
    }
    .datatable-body-row {
      background: $white;
      margin-top: 8px;
      border-radius: 4px;
      box-shadow: inset 0 1px 0 0 $white-grey, inset 0 -1px 0 0 $white-grey, inset -1px 0 0 0 $white-grey;
      .datatable-body-cell {
        .datatable-body-cell-label {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          padding-left: 24px;
        }
        .year-progress {
          width: 100%;
          background-color: $lighterGray;
          height: 10px;
          padding: 0;
          .progress {
            height: 100%;
            background-color: #0F6CFF;
            border-radius: 1px;
            &.projectCompleted {
              background-color: black;
            }
          }
        }
        &:last-child {
          .datatable-body-cell-label {
            padding-left: 0;
            align-items: flex-start;
            .last-row {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              height: 56px;
              width: 100%;
              .year {
                height: 46px;
                padding-left: 24px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .tcell-user {
          display: flex;
          .icon-avatar {
            width: 32px;
            height: 32px;
            border-radius: 16px;
            margin-right: 16px;
          }
        }
        &:first-child {
          .datatable-body-cell-label {
            padding-left: 0;
            align-items: flex-start;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: $text;
            .country-header {
              padding-left: 24px;
              width: 100%;
              height: 56px;
              display: flex;
              align-items: center;
              background-position: center left;
              font-size: 16px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: $text;
            }
          }
        }
      }
    }
  }
}

.base-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $lighterGray;
  border-bottom: 0;
  tr {
    th, td {
      vertical-align: middle;
      .checkbox-wrap {
        width: 40px;
        height: 40px;
        margin: 0;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-right: 1px solid $lighterGray;
        input {
          display: none;
        }
        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
    th {
      height: 40px;
      border-bottom: 1px solid $lighterGray;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 4;
      letter-spacing: normal;
      color: $lightBlack;
      padding-left: 16px;
      &:first-child {
        padding-left: 16px;
        width: 40px;
      }
      .sort-btn {
        width: 16px;
        height: 16px;
        &.sort-asc {
          background: url('/assets/i/sort-down.svg') no-repeat;
          background-size: 16px 16px;
          margin-left: 16px;
        }
        &.sort-desc {
          background: url('/assets/i/sort-down.svg') no-repeat;
          background-size: 16px 16px;
          margin-left: 16px;
          transform: rotate(180deg);
        }
      }
      .default-sort-btn {
        width: 16px;
        height: 16px;
        background: url('/assets/i/sort-down.svg') no-repeat;
        background-size: 16px 16px;
        margin-left: 3px;
        margin-bottom: -3px;
        &.sort-asc {
          background: url('/assets/i/sort-down.svg') no-repeat;
          background-size: 16px 16px;
          margin-left: 3px;
          margin-bottom: -3px;
        }
        &.sort-desc {
          background: url('/assets/i/sort-down.svg') no-repeat;
          background-size: 16px 16px;
          margin-left: 3px;
          margin-bottom: -3px;
          transform: rotate(180deg);
        }
      }
      &:last-child {
        position: relative;
        .filter-container {
          width: 180px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1000;
          background-color: #3D3D3D;
          display: flex;
          flex-direction: column;
          color: white;
          padding: 0 0 16px 16px;
          border-radius: 5px;
          user-select: none;
          .header {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #686868;
            height: 41px;
            .icon {
              width: 40px;
              height: 40px;
              background-size: 40%;
              cursor: pointer;
            }
          }
          .filter-option {
            height: 30px;
            display: flex;
            align-items: center;
            .icon {
              width: 16px;
              height: 16px;
            }
            span {
              padding-left: 5px;
              font-size: 13px;
            }
          }
          &.filter-hide {
            background-color: transparent;
            // max-width: 100%;
            padding-bottom: 0;
            width: 70px;
            .header {
              border-bottom: none;
              span {
                display: none;
              }
            }
            .filter-option {
              display: none;
            }
          }
        }
      }
    }
    td {
      height: 40px;
      border-bottom: 1px solid $lighterGray;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $lightBlack;
      padding-left: 16px;
      &:first-child {
        padding-left: 0;
        width: 40px;
      }
    }
  }
}

.ae-base-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $lighterGray;
  border-bottom: 0;
  tr {
    th, td {
      vertical-align: middle;
      .checkbox-wrap {
        width: 40px;
        height: 40px;
        margin: 0;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-right: 1px solid $lighterGray;
        input {
          display: none;
        }
        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
    th {
      height: 40px;
      border-bottom: 1px solid $lighterGray;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 4;
      letter-spacing: normal;
      color: $lightBlack;
      padding-left: 16px;
      width: 95px;
      &:first-child {
        padding-left: 0;
        width: 40px;
      }
      .sort-btn {
        width: 12px;
        height: 12px;
        &.sort-asc {
          background: url('/assets/i/sort-down.svg') no-repeat;
          background-size: 12px 12px;
          margin-left: 16px;
        }
        &.sort-desc {
          background: url('/assets/i/sort-down.svg') no-repeat;
          background-size: 12px 12px;
          margin-left: 16px;
          transform: rotate(180deg);
        }
      }
      &:last-child {
        position: relative;
        .filter-container {
          width: 180px;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1000;
          background-color: #3D3D3D;
          display: flex;
          flex-direction: column;
          color: white;
          padding: 0 0 16px 16px;
          border-radius: 5px;
          user-select: none;
          .header {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #686868;
            height: 41px;
            .icon {
              width: 40px;
              height: 40px;
              background-size: 40%;
              cursor: pointer;
            }
          }
          .filter-option {
            height: 30px;
            display: flex;
            align-items: center;
            .icon {
              width: 16px;
              height: 16px;
            }
            span {
              padding-left: 5px;
              font-size: 13px;
            }
          }
          &.filter-hide {
            background-color: transparent;
            // max-width: 100%;
            padding-bottom: 0;
            width: 70px;
            .header {
              border-bottom: none;
              span {
                display: none;
              }
            }
            .filter-option {
              display: none;
            }
          }
        }
      }
    }
    td {
      height: 40px;
      border-bottom: 1px solid $lighterGray;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: $lightBlack;
      padding-left: 16px;
      &:first-child {
        padding-left: 0;
        width: 40px;
      }
    }
  }
}

.pagination {
  padding-top: 16px;
  @include paging-default();
}
.freeze-table {
  position: relative;
  &.lg {
    .wrapper {
      height: 280px;
    }
  }
  .base-table {
    tr {
      th {
        background: $white;
      }
    }
  }
  .wrapper {
    width: 100%;
    position: relative;
    height: 220px;
    .base-table .filter-container {
      display: none;
    }
  }
  .filter-section {
    height: 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    .filter-container {
      position: absolute;
      @include filter-container();
    }
  }
  .fixed-wrapper {
    border-bottom: 1px solid $lighterGray;
    position: absolute;
    background: $white;
    top: 0;
    left: 0;
    height: 45px;
    width: 100%;
    overflow: hidden;
  }
}
