@import "./styles/imports";
@import "mixin";
@import "./styles/fonts";
@import "./styles/icons";
@import "./styles/tables";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "ngx-toastr/toastr";
@import "~ol/ol.css";
@import "~c3/c3.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'material-icons/iconfont/material-icons.css';
@import 'tippy.js/dist/tippy.css';

// Do not tamper with this unless you know what this is for.
.gsfBackground {
  border-color: #fa987b;
  opacity: 98%;
  margin-bottom: 19px;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
body::-webkit-scrollbar {
  display: none;
}

.placeholder {
  cursor: inherit;
}

/* Hide scrollbar */
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modal_scrollable {
  max-height: 55vh;
  overflow: auto;
  overflow-x: hidden;
}

canvas:focus {
  outline: none;
}
.as_contents {
  display: contents;
}

.icons {
  display: inline-block;
  vertical-align: middle;
}

.flex-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gshHeader {
  border-color: #8ec89a;
  font-size: 12;
  margin-bottom: 3;
}

.hide {
  display: none !important;
}

//.content
.content {
  padding: 32px 24px 20px;
  &.pt88 {
    padding-top: 112px;
  }
}
/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ngb-modal-window {
  position: absolute;
  top: 20%;
  left: 33%;
  z-index: 99;
  pre:empty {
    display: none;
  }
}

.modal.fade .modal-dialog,
.modal.show .modal-dialog {
  transform: none;
  -webkit-transform: none;
}

.loading-box {
  position: fixed;
  z-index: 15000;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .img {
    background-image: url("./assets/i/loading.gif");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    height: 100px;
    width: 100px;
    margin: 0 auto;
  }
}

.full-screen-modal-backdrop {
  // position: inherit !important;
  opacity: 0 !important;
  top: 0;
  bottom: 0;
  background-color: $bgColor;
}

.full-screen-modal-window {
  position: absolute;
  overflow-y: hidden !important;
  top: 88px;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  padding: 16px;
  background-color: $bgColor;
  height: calc(100% - 88px);
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    height: 100%;
    .modal-content {
      border: none;
      height: 100%;
      display: block;
      // width: auto;
    }
  }
}

.large-screen-modal-window {
  .modal-dialog {
    margin: 0 10%;
    max-width: 100%;
  }
}

.large-diagram-modal-window {
  .modal-dialog {
    margin: 0 10%;
    min-width: 992px;
    max-width: 1000px;
  }
}

.medium-screen-recommendation-modal-window {
  .modal-dialog {
    max-width: 640px;
    .sub-page .viewport {
      margin: 0;
      padding: 0;
    }
  }
}

.medium-screen-modal-window {
  .modal-dialog {
    max-width: 840px;
    .sub-page .viewport {
      margin: 0;
      padding: 0;
    }
  }
}

// viewport
.viewport {
  position: relative;
}

// buttons
.btn {
  width: 104px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #0f00ff;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  padding: 0;
  min-width: 176px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  outline: none;
  border: none;

  &.btn-gray {
    border: solid 1px #9f9f9f;
    background-color: #dbdbdb;
    color: #262626;
  }
  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
  &.btn-clear {
    background-color: transparent;
    color: #0f00ff;
  }
  &.width-auto {
    min-width: auto;
  }
  &.btn-primary {
    color: $white;
    background-color: $black;
  }
  &.btn-primary:hover, &.btn-primary:focus, &.btn-primary:active, &.btn-primary.active, &.open>&.dropdown-toggle.btn-primary {
    background-color: black;
    color: $white;
  }n
  &.btn-secondary:hover, &.btn-secondary:focus, &.btn-secondary:active, &.btn-secondary.active, &.open>&.dropdown-toggle.btn-secondary {
    background-color: $white;
    color: black;
  }
  &.btn-blue {
    color: $white;
    background-color: $primary-blue;
    height: 40px;
  }
  &.btn-secondary {
    color: $black;
    border: 1px solid rgba(159, 159, 159, 0.8);
    background-color: $white;;
  }
  &.btn-sm {
    width: 78px;
    height: 24px;
    line-height: 24px;
    border-radius: 3px;
    font-size: 10.5px;
    text-align: center;
    cursor: pointer;
    padding: 0;
    min-width: 132px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    outline: none;
    border: none;
  }
  &.btn-xs {
    width: 52px;
    height: 16px;
    line-height: 16px;
    border-radius: 2px;
    font-size: 7px;
    text-align: center;
    cursor: pointer;
    padding: 0;
    min-width: 88px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    outline: none;
    border: none;
  }
  &.btn-link {
    color: $black;
    background-color: transparent;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:hover {
    opacity: 0.8;
  }
  + .btn {
    margin-left: 15px;
  }

  &.btn-add {
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 500;
    border: 1px solid #bababa;
    background-color: white;
    border-radius: 2px;
    color: $black;
    width: 100%;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      border-color: #bababa;
    }

    img {
      height: 32px;
      width: 41px;
      padding: 8px 12px;
      border-right: 1px solid #bababa;
      margin-right: 12px;
    }
  }
}

.onlyView {
  pointer-events: none !important;
}

//select
.select-ctrl {
  -webkit-appearance: none;
  width: 272px;
  height: 32px;
  border: solid 1px #9f9f9f;
  background: #f4f4f4 url("./assets/i/chevron-down-dark.svg") top 6px right 4px
    no-repeat;
  background-size: 24px 20px;
  outline: none;
  border-radius: 0;
  padding: 0 7px;
  line-height: normal;
  &.dark {
    background: transparent url("./assets/i/chevron-down-lgt.svg") top 6px left
      0 no-repeat;
    color: #fff;
    font-size: 12px;
    border: none;
    width: 100px;
    padding-left: 18px;
  }

  &:disabled {
    border-color: #9f9f9f;
    background-color: #dbdbdb;
  }
}

// custome-select
.ng-select {
  outline: none;
  &.hide-val {
    &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
      display: none;
    }
  }
  &.grayed {
    background-color: rgba(#dbdbdb, 0.8);
  }
  &.ng-select-multiple .ng-select-container {
    .ng-value-container {
      .ng-placeholder {
        top: 10px;
      }
      .ng-value {
        border-radius: 12px;
        border: solid 1px #bababa;
        background-color: rgba(#dbdbdb, 0.8);
        height: 24px;
        line-height: 22px;
        display: flex;
        flex-direction: row-reverse;
        .ng-value-icon.left {
          border-right: none;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  .ng-select-container {
    background-color: transparent;
    .ng-value-container {
      font-size: 14px;
      color: #262626;
    }
    .ng-arrow-wrapper {
      line-height: 10px;
      .ng-arrow {
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        height: 6px;
        width: 6px;
        border-color: #464646;
        vertical-align: top;
      }
    }
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 14px;
    color: #262626;
  }
}


/* checkbox */

// usage: label.checkbox-wrap -> input[type=checkbox] + i.icon.icon-checkbox.checked
.checkbox-wrap {
  width: 40px;
  height: 40px;
  line-height: 48px;
  margin: 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    display: none;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.checkbox-ctrl {
  position: relative;
  .chk {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }
  .txt {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: solid 1px rgba(#bababa, 0.8);
    }
  }
  .chk:checked + .txt {
    &:before {
      background: #0f00ff url("./assets/i/chk-a.svg") 0 0 no-repeat;
      background-size: 20px;
    }
  }
  &.style-switch {
    line-height: 24px;
    .txt {
      padding-left: 60px;
      line-height: 24px;
      font-size: 14px;
      color: #464646;
      &:before {
        width: 48px;
        height: 24px;
        box-sizing: border-box;
        border-radius: 12px;
        background-color: #fff;
        border: 1px solid in#bababa;
      }
      &:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff url("./assets/i/chk-r-ck.png") 50% 50% no-repeat;
      }
    }
    .chk:checked + .txt {
      &:before {
        border: 1px solid #0f00ff;
        background: #0f00ff;
        background-size: 16px;
        height: 24px;
      }
      &:after {
        content: "";
        position: absolute;
        left: 26px;
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff url("./assets/i/chk-r-ck.png") 50% 50% no-repeat;
      }
    }
  }
}

/* radio */

.radio-ctrl {
  position: relative;
  .chk {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }
  .txt {
    font-size: 14px;
    color: #464646;
    padding-left: 24px;
    line-height: 24px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: solid 1px rgba(#bababa, 0.8);
    }
  }
  .chk:checked + .txt {
    &:before {
      background: #0f00ff url("./assets/i/chk-a.svg") 0 0 no-repeat;
      background-size: 18px;
      border: solid 1px rgba(#0f00ff, 0.8);
    }
  }
}

// input-ctrl
.input-ctrl {
  height: 32px;
  line-height: 32px;
  padding: 0 7px;
  width: 100%;
  font-size: 14px;
  color: #262626;
  background: #FBFCFC 0% 0% no-repeat padding-box;
  border: 1px solid #414141;
  border-radius: 2px;
  &.search-ctrl {
    background: url("./assets/i/search.svg") top 8px right 8px no-repeat;
    background-size: 15px;
    width: 192px;
  }
}

.search-box-container {
  position: relative;

  .search-ctrl {
    border-radius: 2px;
  }

  .search-btn {
    margin: 1px;
    position: absolute;
    right: 0;
    top: 0;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    &.search-btn-clear {
      height: 24px;
      width: 24px;
      margin: 4px 2px;
    }
  }
}

.search-ctrl-select {
  background-color: #fff;
  &.ng-select.grayed {
    background-color: #fff;
  }
  .ng-clear-wrapper {
    margin-right: 22px;
  }
  .ng-value {
    display: none;
  }
  .ng-arrow-wrapper {
    display: none;
  }
  .ng-select-container {
    background: url("./assets/i/search.svg") top 8px right 8px no-repeat;
    background-size: 15px;
  }
}
.tooltip.show {
  opacity: 1;
}
textarea.input-ctrl {
  padding-top: 6px;
  height: 62px;
  line-height: 1.2;
  &.h-lg {
    height: 184px;
  }
  &.h-md {
    height: 120px;
  }
}

// switch-ctrl
.switch-ctrl {
  position: relative;
  display: inline-block;
  .chk {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }
  .txt {
    padding-left: 96px;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 80px;
      height: 24px;
      border-radius: 12px;
      background-color: #0f00ff;
    }
    &:after {
      content: "View";
      position: absolute;
      left: 1px;
      top: 1px;
      width: 38px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      color: #0f00ff;
      z-index: 10;
    }
    i {
      position: absolute;
      left: 2px;
      top: 2px;
      width: 38px;
      height: 20px;
      border-radius: 12px;
      background-color: #ffffff;
    }
    .sl {
      position: absolute;
      left: 40px;
      top: 1px;
      width: 38px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      z-index: 10;
    }
  }
  .chk:checked + .txt {
    &:after {
      color: #fff;
    }
    i {
      left: 40px;
    }
    .sl {
      color: #0f00ff;
    }
  }
  &.disabled {
    position: relative;
    pointer-events: none;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 20;
    }
    .txt {
      &:before {
        background-color: #dbdbdb;
      }
      &:after {
        color: #dbdbdb;
      }
    }
    .chk:checked + .txt {
      .sl {
        color: #dbdbdb;
      }
    }
    .ls {
      background: #000;
    }
  }
}

/* sub-page */

.sub-page {
  padding-top: 0;
  height: 100%;
  max-height: 100%;
  .viewport {
    padding: 0 24px;
    height: 100%;
    max-height: 100%;
    &.width-fixed {
      margin: 0 auto;
    }
  }
  .sub-header {
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    border-bottom: 1px solid $lighterGray;
    &.sub-header-sm {
      height: 40px;
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: #262626;
    }
    &.fx {
      display: flex;
      justify-content: space-between;
    }
    .lnk {
      font-size: 13px;
      line-height: 30px;
      color: #0f00ff;
    }
  }
  .scroll-container {
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
    overflow-y: scroll;
  }
}

// cards-group
.cards-group {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  .card-default {
    flex: 0 296px;
    min-width: 296px;
    height: 576px;
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    &.height-md {
      height: 384px;
    }
    &.height-sm {
      height: 176px;
    }
    &.falign-end {
      align-self: flex-end;
    }
    .card-header {
      font-size: 20px;
      font-weight: 500;
      line-height: 47px;
      height: 48px;
      color: #262626;
      border-bottom: 1px solid #f4f4f4;
      text-align: center;
    }
    .card-body {
      height: 488px;
      .desc {
        padding: 18px 24px;
      }
      .ta-center {
        font-size: 13px;
        line-height: 1.46;
        text-align: center;
        color: #262626;
      }
      &.h-auto {
        height: auto;
      }
      &.h-kb {
        height: 90px;
      }
      .risk-mat {
        width: 240px;
        height: 240px;
        padding-bottom: 24px;
        margin: 24px 28px;
        background: url("./assets/i/risk-matrix.png") 0 0 no-repeat;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
      }
    }
    .card-actions {
      display: flex;
      justify-content: space-between;
      padding: 0 32px;
      &.a-center {
        justify-content: center;
      }
      &.centered {
        justify-content: center;
      }
      .lnk {
        font-size: 14px;
        line-height: 1.14;
        // text-align: center;
        color: #0f00ff;
        width: 96px;
        text-align: center;
      }
    }
  }
  // card-multiple
  .card-multiple {
    flex: 0 296px;
    min-width: 296px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }
}

// bootstrap overrides
.modal-dialog.modal-xxl {
  max-width: min(calc(100% - 60px), 1600px);

  @include tablet-and-below {
    max-width: 100%;
    margin: 30px 0;
    min-height: 0;
    height: calc(100% - 60px);

    .modal-content {
      height: 100%;
      border-radius: 0;

      & > * {
        height: 100%;
      }
    }
  }
}

.modal-wrap1 {
  position: fixed;
  //background-color: rgba(#3d3d3d, 0.75);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center; // modal-role
  .modal-role {
    width: 1024px;
    height: 478px;
    border-radius: 4px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    .modal-title {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        color: #262626;
      }
      .close {
        width: 24px;
        height: 24px;
        background: url("./assets/i/close.svg") 0 0 no-repeat;
        background-size: 24px;
      }
    }
    .owl-stage-outer {
      padding: 24px 16px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
        bottom: 0;
        width: 150px;
        background-image: linear-gradient(
          to left,
          #ffffff,
          rgba(255, 255, 255, 0) 98%
        );
      }
    }
    .cards-role {
      .card-role {
        width: 191px;
        height: 370px;
        border-radius: 4px;
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        border: 1px solid transparent;
        &.selected {
          border: 1px solid #6f81f4;
        }
        .fig {
          text-align: center;
          padding-top: 18px;
          img {
            width: 40px;
            height: 40px;
            display: inline-block;
          }
        }
        .fig-caption {
          font-size: 14px;
          line-height: 1.14;
          text-align: center;
          color: #262626;
          padding: 16px 16px 0;
        }
        .cr-head {
          height: 120px;
          padding-bottom: 16px;
        }
        .role-desc {
          font-size: 13px;
          line-height: 1.46;
          text-align: center;
          color: #262626;
          padding: 16px;
          border-top: 1px solid #f4f4f4;
          height: 182px;
          overflow: hidden;
        }
      }
    }
    .bar-action {
      display: flex;
      justify-content: flex-end;
      padding: 0 24px;
      .btn {
        width: 160px;
      }
    }
    .owl-item {
      width: 192px;
    }
    .card-role {
      height: 328px;
      border-radius: 3px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    }
  } // modal-permission
  .modal-permission1 {
    width: 60% !important;
    height: 85%;
    // min-height: 710px;
    padding-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    .modal-title {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        color: #262626;
      }
      .close {
        width: 24px;
        height: 24px;
        background: url("./assets/i/close.svg") 0 0 no-repeat;
        background-size: 24px;
      }
    }
    .owl-stage-outer {
      padding: 24px 16px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
        bottom: 0;
        width: 150px;
        background-image: linear-gradient(
          to left,
          #ffffff,
          rgba(255, 255, 255, 0) 98%
        );
      }
    }
    .bar-action {
      position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      justify-content: flex-end;
      padding: 24px 24px 0;
      margin-top: 20px;
      border-top: 1px solid #f4f4f4;
      .btn {
        width: 160px;
      }
    }
    .owl-item {
      width: 192px;
    }
    .card-role {
      height: 328px;
      border-radius: 3px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    }
    // permission-list
    .permission-list {
      // permission-group
      .permission-group {
        padding: 24px 24px 0 24px;
      }
      .pg-head {
        padding-bottom: 24px;
        .txt {
          margin-left: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #262626;
        }
      }
      .pg-body {
        .pg-row {
          height: 24px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          .txt {
            line-height: 24px;
            font-size: 14px;
            color: #464646;
          }
        }
      }
    }
  }
  // modal-new-execution
  .modal-new-execution {
    width: 388px;
    height: auto;
    // min-height: 275px;
    border-radius: 4px;
    // box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    .modal-title {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        color: #262626;
      }
      .close {
        width: 24px;
        height: 24px;
        background: url("./assets/i/close.svg") 0 0 no-repeat;
        background-size: 24px;
      }
    }
    .owl-stage-outer {
      padding: 24px 16px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
        bottom: 0;
        width: 150px;
        background-image: linear-gradient(
          to left,
          #ffffff,
          rgba(255, 255, 255, 0) 98%
        );
      }
    }
    .bar-action {
      display: flex;
      justify-content: flex-end;
      padding: 24px 24px 0;
      margin-top: 20px;
      border-top: 1px solid #f4f4f4;
      .btn {
        width: 160px;
      }
    }
    .owl-item {
      width: 192px;
    }
    .card-role {
      height: 328px;
      border-radius: 3px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    } // permission-list
    .permission-list {
      // permission-group
      .permission-group {
        padding: 24px 24px 0 24px;
      }
      .pg-head {
        padding-bottom: 24px;
        .txt {
          margin-left: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #262626;
        }
      }
      .pg-body {
        .pg-row {
          height: 24px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          .txt {
            line-height: 24px;
            font-size: 14px;
            color: #464646;
          }
        }
      }
    }
    .modal-body {
      padding: 24px;
      .bar-action {
        margin: 0 -24px;
      }
      .frow {
        margin-bottom: 21px;
        .fs-lbl {
          font-size: 14px;
          line-height: 20px;
          color: #686868;
          margin-bottom: 4px;
        }
        .fs-val {
          .txt-val {
            height: 36px;
            line-height: 36px;
          }
        }
        .fieldset {
          &.inline {
            display: flex;
            justify-content: space-between;
            .fs-lbl {
              margin-bottom: 0;
            }
          }
        }
        .switch-ctrl {
          &.lg {
            width: 164px;
            .txt {
              &:before {
                width: auto;
                right: 0;
              }
              &:after {
                content: "Internal";
                width: 80px;
              }
              i {
                width: 80px;
              }
              .sl {
                left: 100px;
              }
            }
            .chk:checked + .txt i {
              left: 80px;
            }
          }
        }
      }
    }
  }
  .modal {
    position: relative;
    display: block;
  }
  // modal-manage-skill
  .modal-manage-skill {
    width: 420px;
    height: auto;
    min-height: 355px;
    border-radius: 4px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    &.h-auto {
      min-height: 120px;
    }
    .modal-title {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        color: #262626;
      }
      .close {
        width: 24px;
        height: 24px;
        background: url("./assets/i/close.svg") 0 0 no-repeat;
        background-size: 24px;
      }
    }
    .owl-stage-outer {
      padding: 24px 16px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
        bottom: 0;
        width: 150px;
        background-image: linear-gradient(
          to left,
          #ffffff,
          rgba(255, 255, 255, 0) 98%
        );
      }
    }
    .bar-action {
      display: flex;
      justify-content: flex-end;
      padding: 24px 24px 0;
      margin-top: 20px;
      border-top: 1px solid #f4f4f4;
      .btn {
        width: 160px;
        margin-left: 20px;
      }
    }
    .owl-item {
      width: 192px;
    }
    .card-role {
      height: 328px;
      border-radius: 3px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    } // permission-list
    .permission-list {
      // permission-group
      .permission-group {
        padding: 24px 24px 0 24px;
      }
      .pg-head {
        padding-bottom: 24px;
        .txt {
          margin-left: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #262626;
        }
      }
      .pg-body {
        .pg-row {
          height: 24px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          .txt {
            line-height: 24px;
            font-size: 14px;
            color: #464646;
          }
        }
      }
    }
    .modal-body {
      padding: 24px;
      .msg {
        padding-bottom: 50px;
        padding-top: 20px;
        text-align: center;
      }
      .bar-action {
        margin: 0 -24px;
      }
      .frow {
        margin-bottom: 21px;
        &.seperation-t-md {
          margin-top: 48px;
        }
        &.mg-t-md {
          margin-top: 32px;
        }
        .fs-lbl {
          font-size: 14px;
          line-height: 20px;
          color: #686868;
          margin-bottom: 4px;
        }
        .fs-val {
          .txt-val {
            height: 36px;
            line-height: 36px;
          }
        }
        .fieldset {
          &.inline {
            display: flex;
            justify-content: space-between;
            .fs-lbl {
              margin-bottom: 0;
            }
          }
          .fs-val {
            .radio-group {
              .radio-ctrl:first-child {
                margin-right: 12px;
              }
            }
          }
        }
        .switch-ctrl {
          &.lg {
            width: 164px;
            .txt {
              &:before {
                width: auto;
                right: 0;
              }
              &:after {
                content: "Internal";
                width: 80px;
              }
              i {
                width: 80px;
              }
              .sl {
                left: 100px;
              }
            }
            .chk:checked + .txt i {
              left: 80px;
            }
          }
        }
      }
    }
    .fs-val-validity {
      display: flex;
      justify-content: space-between;
      .select-ctrl {
        border-radius: 4px;
        border: solid 1px #9f9f9f;
        background-color: rgba(#dbdbdb, 0.8);
        height: 32px;
        line-height: 30px;
        padding: 0 7px;
        font-size: 14px;
        color: #262626;
        width: 90px;
      }
    }
    .btn-upload-certificate {
      border-radius: 4px;
      border: solid 1px #9f9f9f;
      background-color: rgba(#dbdbdb, 0.8);
      width: 160px;
      font-size: 14px;
      text-align: center;
      color: #262626;
      position: relative;
      .file {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
      }
    }
  } // modal-manage-skills
  .modal-manage-skills {
    width: 656px;
    height: auto;
    min-height: 255px;
    border-radius: 4px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    .modal-title {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f4f4f4;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 45px;
        color: #262626;
      }
      .close {
        width: 24px;
        height: 24px;
        background: url("./assets/i/close.svg") 0 0 no-repeat;
        background-size: 24px;
      }
    }
    .bar-search-skill {
      .input-ctrl {
        font-size: 14px;
        background: url("./assets/i/search.svg") top 8px right 8px no-repeat;
        background-size: 15px;
      }
    }
    .bar-action {
      display: flex;
      justify-content: flex-end;
      padding: 24px 24px 0;
      margin-top: 20px;
      border-top: 1px solid #f4f4f4;
      .btn {
        width: 160px;
      }
    }
    .owl-item {
      width: 192px;
    }
    .card-role {
      height: 328px;
      border-radius: 3px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    } // permission-list
    .permission-list {
      // permission-group
      .permission-group {
        padding: 24px 24px 0 24px;
      }
      .pg-head {
        padding-bottom: 24px;
        .txt {
          margin-left: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #262626;
        }
      }
      .pg-body {
        .pg-row {
          height: 24px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          .txt {
            line-height: 24px;
            font-size: 14px;
            color: #464646;
          }
        }
      }
    }
    .modal-body {
      padding: 24px;
      .bar-action {
        margin: 0 -24px;
      }
      .frow {
        margin-bottom: 21px;
        .fs-lbl {
          font-size: 14px;
          line-height: 20px;
          color: #686868;
          margin-bottom: 4px;
        }
        .fs-val {
          .txt-val {
            height: 36px;
            line-height: 36px;
          }
        }
        .fieldset {
          &.inline {
            display: flex;
            justify-content: space-between;
            .fs-lbl {
              margin-bottom: 0;
            }
          }
        }
        .switch-ctrl {
          &.lg {
            width: 164px;
            .txt {
              &:before {
                width: auto;
                right: 0;
              }
              &:after {
                content: "Internal";
                width: 80px;
              }
              i {
                width: 80px;
              }
              .sl {
                left: 100px;
              }
            }
            .chk:checked + .txt i {
              left: 80px;
            }
          }
        }
      }
    }
    .fs-val-validity {
      display: flex;
      justify-content: space-between;
      .select-ctrl {
        border-radius: 4px;
        border: solid 1px #9f9f9f;
        background-color: rgba(#dbdbdb, 0.8);
        height: 32px;
        line-height: 30px;
        padding: 0 7px;
        font-size: 14px;
        color: #262626;
        width: 90px;
      }
    }
    .btn-upload-certificate {
      border-radius: 4px;
      border: solid 1px #9f9f9f;
      background-color: rgba(#dbdbdb, 0.8);
      width: 160px;
      font-size: 14px;
      text-align: center;
      color: #262626;
      position: relative;
      .file {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
      }
    }
    .skills-list {
      overflow: hidden;
      padding-top: 6px;
      padding-bottom: 24px;
      .skill-tag {
        margin-top: 16px;
        border-radius: 12px;
        border: solid 1px #bababa;
        background-color: rgba(#dbdbdb, 0.8);
        height: 24px;
        line-height: 22px;
        display: inline-block;
        padding: 0 2px 0 9px;
        position: relative;
        color: #262626;
        font-size: 13px;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        &.system {
          background-color: #dbdbdb;
          padding-right: 10px;
          opacity: 0.4;
        }
        .ng-value-icon.left {
          border-right: none;
          &:hover {
            background-color: transparent;
          }
        }
        .remove-st {
          padding: 0 5px;
          display: inline-block;
          margin-left: 4px;
        }
      }
    }
  } // modal-kw-bank
  .modal-kw-bank {
    width: 816px;
    .tags-list {
      min-height: 160px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
      margin-top: 32px;
      padding: 16px;
      &:first-child {
        margin-top: 0;
      }
      .header-tl {
        border-bottom: 1px solid #d8d8d8;
        display: flex;
        justify-content: space-between;
        padding-bottom: 7px;
      }
      h3 {
        font-size: 16px;
        font-weight: 500;
        color: #262626;
        line-height: 24px;
      }
      .lnk {
        line-height: 24px;
        font-size: 13px;
        color: #0f00ff;
      }
    }
  } // modal-new-category
  // .modal-new-category1 {
  //   width: 388px;
  //   height: auto;
  //   min-height: 215px;
  //   border-radius: 4px;
  //   box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
  //   background-color: #ffffff;
  //   .modal-body {
  //     padding: 24px;
  //     .bar-action {
  //       margin: 0 -24px;
  //     }
  //     .frow {
  //       margin-bottom: 21px;
  //       .fs-lbl {
  //         font-size: 14px;
  //         line-height: 20px;
  //         color: #686868;
  //         margin-bottom: 4px;
  //       }
  //       .fs-val {
  //         .txt-val {
  //           height: 36px;
  //           line-height: 36px;
  //         }
  //       }
  //       .fieldset {
  //         &.inline {
  //           display: flex;
  //           justify-content: space-between;
  //           .fs-lbl {
  //             margin-bottom: 0;
  //           }
  //         }
  //       }
  //       .switch-ctrl {
  //         &.lg {
  //           width: 164px;
  //           .txt {
  //             &:before {
  //               width: auto;
  //               right: 0;
  //             }
  //             &:after {
  //               content: 'Internal';
  //               width: 80px;
  //             }
  //             i {
  //               width: 80px;
  //             }
  //             .sl {
  //               left: 100px;
  //             }
  //           }
  //           .chk:checked + .txt i {
  //             left: 80px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // } // modal-riskmat
  .modal-riskmat {
    width: 814px;
    min-height: 608px;
    .risk-mat-fig-container {
      .fig {
        display: flex;
        justify-content: center;
        height: 400px;
      }
    }
    .bar-action {
      border-top: none;
      padding-top: 50px;
      justify-content: space-between;
      > span {
        width: 160px;
      }
      .lnk {
        font-size: 14px;
        line-height: 32px;
        text-align: center;
        color: #0f00ff;
      }
    }
    .modal-body {
      height: 476px;
    }
// mat-switch
    .mat-switch {
      width: 164px;
      height: 24px;
      margin-left: 16px;
      .txt {
        i {
          width: 80px;
        }
        &:before {
          width: 164px;
        }
        &:after {
          content: "Horizontal";
          width: 80px;
        }
        .sl {
          left: 96px;
          width: 50px;
        }
      }
      .chk:checked + .txt i {
        left: 82px;
      }
    }
    .fig-cation {
      display: flex;
      justify-content: center;
      .lbl {
        line-height: 24px;
        margin-right: 16px;
      }
    }
  }
}

// create-user-form
.create-user-form {
  padding-top: 48px;
  padding-left: 128px;
  padding-right: 128px;
  min-height: 632px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  &.sticky {
    margin: 0 -24px;
    padding: 0 24px;
  }
  .emp-info {
    padding-top: 16px;
    .fig {
      display: flex;
      justify-content: center;
      position: relative;
      width: 88px;
      height: 88px;
      img {
        width: 88px;
        height: 88px;
        border-radius: 50%;
      }
    }
  }
  .ei {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 15px;
    padding-top: 16px;
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #262626;
    }
    .lnk {
      font-size: 13px;
      line-height: 1.85;
      color: #0f00ff;
    }
  }
  .emp-info + .frow {
    padding-top: 16px;
  }
  .frow {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    &.fstart {
      justify-content: flex-start;
    }
    .fieldset {
      flex: 0 504px;
      &.fieldset-sm {
        flex: 0 101px;
      }
      &.fieldset-md {
        flex: 0 296px;
      }
      &.fluid {
        flex: 1;
      }
      .fs-lbl {
        font-size: 14px;
        color: #686868;
        line-height: 20px;
      }
      .fs-val {
        padding-top: 4px;
        font-size: 14px;
        color: #262626;
        &.skills-list {
          padding-top: 16px;
        }
        .txt-val {
          line-height: 36px;
          height: 36px;
        }
        &.fs-val-fx {
          display: flex;
          .select-ctrl {
            margin-right: 16px;
            font-size: 14px;
            color: #262626;
          }
        }
        .input-ctrl {
          height: 32px;
          line-height: 32px;
          border: solid 1px #bababa;
          background-color: #ffffff;
          padding: 0 7px;
          font-size: 14px;
          width: 100%;
        }
      }
      .lnk-manage-skills {
        font-size: 13px;
        line-height: 32px;
        color: #0f00ff;
      }
    }
  }
  .btn-upload-img {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #a7a7a7;
    background-color: #d8d8d8;
    right: -16px;
    bottom: -4px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    color: #262626;
    overflow: hidden;
    cursor: pointer;
    .file {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .select-ctrl {
    width: 88px;
    border-radius: 4px;
    padding: 0 7px;
  }
  .btn-gray {
    width: 120px;
    height: 32px;
    line-height: 30px;
    border-radius: 4px;
    border: solid 1px #9f9f9f;
    background-color: rgba(#dbdbdb, 0.8);
    font-size: 14px;
    text-align: center;
    color: #262626;
  }
  .btn-save-form {
    width: 192px;
    margin-top: 48px;
  }
  .btn-save {
    width: 192px;
    &.space-r {
      margin-right: 32px;
    }
  }
  .bar-action {
    &.fx-r {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 62px;
    }
  }
  .added-roles {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 0;
    .role-tag {
      height: 24px;
      line-height: 22px;
      border-radius: 12px;
      border: solid 1px #bababa;
      background-color: rgba(#dbdbdb, 0.8);
      padding: 0 44px 0 9px;
      cursor: pointer;
      font-size: 13px;
      color: #3d3d3d;
      position: relative;
      margin-right: 25px;
      .remove {
        position: absolute;
        background: url("./assets/i/close.svg") 0 0 no-repeat;
        background-size: 10px;
        width: 10px;
        height: 10px;
        top: 7px;
        right: 8px;
      }
    }
  }
  .btn-gray + .added-roles {
    margin-left: 25px;
  } // section-execution-info
  .section-execution-info {
    margin-top: 28px;
    .ei {
      margin-bottom: 16px;
    }
  }
  .skills-list {
    .skill-tag {
      border-radius: 12px;
      border: solid 1px #bababa;
      background-color: rgba(#dbdbdb, 0.8);
      height: 24px;
      line-height: 22px;
      position: relative;
      display: inline-block;
      padding: 0 2px 0 9px;
      color: #262626;
      font-size: 13px;
      margin-left: 16px;
      margin-bottom: 16px;
      &:first-child {
        margin-left: 0;
      }
      &.alt {
        padding-right: 10px;
      }
      .ng-value-icon.left {
        border-right: none;
        &:hover {
          background-color: transparent;
        }
      }
      .remove-st {
        padding: 0 5px;
        display: inline-block;
        z-index: 1;
        margin-left: 4px;
      }
    }
  }
}

// view-all-user-table-section
.view-all-user-table-section {
  .table-header {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .users-found {
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      color: #262626;
    }
    .search-section {
      .search-ctrl {
        width: 192px;
        height: 32px;
        border: solid 1px #bababa;
        background-color: #ffffff;
        margin-right: 16px;
        font-size: 14px;
        vertical-align: top;
      }
      .select-ctrl {
        font-size: 14px;
        width: 192px;
        vertical-align: top;
      }
    }
  }
  .all-user-table {
    .datatable-header {
      border-bottom: 1px solid #d8d8d8;
      .datatable-header-cell-label {
        font-size: 12px;
        color: #262626;
      }
      .sort-btn {
        width: 4px;
        height: 4px;
        margin-left: 12px;
        text-align: center;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #464646;
        &.datatable-icon-up {
          transform: rotate(180deg);
        }
      }
      .datatable-header-cell:first-child {
        text-align: center;
        .sort-btn {
          display: none;
        }
      }
    }
    .datatable-body-cell {
      height: 55px;
      border-bottom: 1px solid #f4f4f4;
      display: flex;
      align-items: center;
      .datatable-row-group {
        height: 55px;
      }
      .datatable-body-cell-label {
        font-size: 14px;
        color: #262626;
      }
      &:first-child {
        justify-content: center;
      }
    }
    .tcell-user {
      display: flex;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .uname {
        margin-left: 16px;
        color: #262626;
      }
    }
  }
  .bar-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 56px;
  }
}

.all-roles-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  padding-top: 24px;
  .card-role-container {
    padding-right: 16px;
    padding-bottom: 16px; // card-role
    margin-top: 8px;
    .card-role {
      width: 192px;
      height: 328px;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
      background-color: #ffffff;
      border: 1px solid transparent;
      &.selected {
        border: 1px solid #6f81f4;
      }
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      }
      &.card-role-create {
        box-shadow: none;
        border: 1px dashed #dbdbdb;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        .card-create-new-role {
          display: inline-block;
          padding-top: 56px;
          background: url("./assets/i/error-circ.svg") 50% 0 no-repeat;
          background-size: 32px;
          font-size: 14px;
          color: #101010;
        }
      }
      .fig {
        text-align: center;
        padding-top: 18px;
        img {
          width: 40px;
          height: 40px;
          display: inline-block;
        }
      }
      .fig-caption {
        font-size: 14px;
        line-height: 1.14;
        text-align: center;
        color: #262626;
        padding: 16px 16px 0;
      }
      .cr-head {
        height: 120px;
        padding-bottom: 16px;
      }
      .role-desc {
        font-size: 13px;
        line-height: 1.46;
        text-align: center;
        color: #262626;
        padding: 16px;
        border-top: 1px solid #f4f4f4;
        height: 182px;
        overflow: hidden;
      }
    } // card-execu
    .card-execu {
      width: 192px;
      height: 328px;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
      border: 1px solid transparent;
      &.selected {
        border: 1px solid #6f81f4;
      }
      &.card-role-create {
        box-shadow: none;
        border: 1px dashed #dbdbdb;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        .card-create-new-role {
          display: inline-block;
          padding-top: 56px;
          background: url("./assets/i/error-circ.svg") 50% 0 no-repeat;
          background-size: 32px;
          font-size: 14px;
          color: #101010;
        }
      }
      .fig {
        text-align: center;
        padding-top: 18px;
        img {
          width: 40px;
          height: 40px;
          display: inline-block;
        }
      }
      .fig-caption {
        font-size: 14px;
        line-height: 1.14;
        text-align: center;
        color: #262626;
        padding: 16px 16px 0;
      }
      .cr-head {
        height: 71px;
        display: flex;
        align-items: center;
        padding: 0 15px 0 8px;
        .circ-member-count {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          border: solid 3px #f4f4f4;
          background-color: #ffffff;
          text-align: center;
          line-height: 34px;
          font-size: 21px;
          font-weight: 500;
          color: $black;
        }
        .tinfo {
          padding-left: 10px;
        }
        .team-rating {
          margin-left: auto;
          padding-top: 20px;
          background: url("./assets/i/star-d.svg") 50% 0 no-repeat;
          background-size: 16px;
          .ra-val {
            font-size: 13px;
            line-height: 1.08;
            text-align: center;
            color: #000000;
            width: 15px;
          }
        }
        .lbl {
          font-size: 12px;
          line-height: 2;
          color: #686868;
        }
        h4 {
          font-size: 14px;
          line-height: 1.14;
          color: #262626;
        }
      }
      .role-desc {
        font-size: 13px;
        line-height: 1.46;
        text-align: center;
        color: #262626;
        padding: 16px;
        border-top: 1px solid #f4f4f4;
        height: 86px;
        overflow: hidden;
      }
      .lbl-tskills {
        font-size: 12px;
        line-height: 1.17;
        text-align: center;
        color: #686868;
      }
      .tskill-list {
        padding: 4px 16px 24px 16px;
        .tskill {
          height: 24px;
          line-height: 22px;
          opacity: 0.8;
          border-radius: 12px;
          border: solid 1px #bababa;
          background-color: #f4f4f4;
          margin-top: 8px;
          font-size: 13px;
          padding-left: 7px;
          &:first-child {
            margin-top: 0;
          }
        }
      } // cr-footer
      .cr-footer {
        border-top: 1px solid #f4f4f4;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        .hrate {
          font-size: 12px;
        }
        .hrate-v {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.71;
          text-align: right;
          color: #262626;
        }
      }
    }
  }
}

// role-info-section
.role-info-section {
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 632px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  .role-info-bar {
    position: absolute;
    left: 20px;
    top: 70px;
    .role-fig {
      img {
        width: 88px;
        height: 88px;
      }
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #262626;
    }
    .bar-manage-db {
      display: flex;
      justify-content: space-between;
      .lnk {
        font-size: 13px;
        line-height: 1.85;
        color: #0f00ff;
      }
    }
  }
  .frow {
    display: flex;
    margin-bottom: 21px;
    padding: 0 17px;
    padding-top: 16px;
    .fs-lbl {
      font-size: 14px;
      line-height: 20px;
      color: #686868;
      margin-bottom: 4px;
      &.seperation-t-md {
        padding-top: 20px;
      }
      &.fx-sb {
        display: flex;
        justify-content: space-between;
      }
    }
    .fs-val {
      .txt-val {
        height: 36px;
        line-height: 36px;
      }
    }
    .fieldset {
      &.inline {
        display: flex;
        justify-content: space-between;
        .fs-lbl {
          margin-bottom: 0;
        }
      }
    }
  } // pm-n-users
  .pm-n-users {
    display: flex;
    justify-content: space-between;
    > .col {
      padding-right: 8px;
      &:last-child {
        padding-right: 0;
        padding-left: 8px;
        margin-bottom: 28px;
      }
      h3 {
        border-bottom: 1px solid #d8d8d8;
        line-height: 24px;
        padding-bottom: 15px;
        font-size: 16px;
        font-weight: 500;
        &.fx {
          display: flex;
          justify-content: space-between;
        }
        .rating {
          display: inline-block;
          padding-left: 21px;
          background: url("./assets/i/star-d.png") 0 3px no-repeat;
          background-size: 16px;
        }
      }
    } // permission-list
    .permission-list {
      // permission-group
      display: flex;
      flex-wrap: wrap;
      .permission-group {
        padding: 24px 24px 0 24px;
        flex: 0 50%;
      }
      .pg-head {
        padding-bottom: 24px;
        .txt {
          margin-left: 24px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #262626;
          margin-top: -4px;
        }
      }
      .pg-body {
        .pg-row {
          height: 24px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0;
          }
          .txt {
            line-height: 24px;
            font-size: 14px;
            color: #464646;
          }
        }
      }
    }
  } // user-list
  .user-list {
    padding-top: 0;
    &.seperation-b {
      padding-top: 20px;
    }
    &.has-p-t {
      padding-top: 20px;
    }
    .heading {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 26px;
      justify-content: space-between;
      font-size: 14px;
      color: #686868;
    }
  } // bar-user
  .bar-user {
    border-radius: 4px;
    border: solid 1px #f4f4f4;
    background-color: #ffffff;
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    // position: relative;
    height: 56px;
    margin-bottom: 12px;
    &.undefined {
      border: solid 1px #0f80ff;
    }
    &:first-child {
      margin-top: 0;
    }
    .rating {
      display: inline-block;
      padding-left: 23px;
      position: relative;
      &:after {
        content: "";
        width: 16px;
        height: 16px;
        background: url("./assets/i/star-d.png") 0 0 no-repeat;
        background-size: 16px;
        opacity: 0.2;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .remove-user {
      position: absolute;
      right: 16px;
      top: 20px;
      width: 16px;
      height: 16px;
      background: url("./assets/i/remove-usr.png") 0 0 no-repeat;
      background-size: 16px;
    }
    .thumb {
      margin: 0 16px 0 0;
      width: 32px;
      height: 32px;
      img {
        vertical-align: middle;
        border-style: none;
        width: 100%;
        height: 100%;
      }
    }
    .user-details {
      flex: 1;
      .name {
        font-size: 14px;
        line-height: 1.14;
        color: #262626;
      }
      .job-position {
        font-size: 12px;
        line-height: 2;
        color: #686868;
      }
    }
    .hourly-rate {
      margin-right: 34px;
      font-size: 14px;
      color: #262626;
    }
  } // frow-assign
  .frow-assign {
    padding-bottom: 40px;
    border-bottom: 1px solid #d8d8d8;
    .fieldset {
      flex: 1;
      &:last-child {
        padding-left: 0;
      }
    }
  }
  .bar-action {
    padding-bottom: 12px;
    &.fx-r {
      display: flex;
      justify-content: flex-end;
    }
    padding-top: 12px;
    .btn-save {
      width: 192px;
    }
    .btn-clear {
      background-color: transparent;
      color: #0f00ff;
    }
  }
}

// sub-page-execution
.sub-page-execution {
  .header-exeu {
    border-bottom: 1px solid $lighterGray;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.seperation-t-md {
      margin-top: 24px;
    }
    h2 {
      font-size: 16px;
      font-weight: 400;
      color: #262626;
    }
  }
}

.chk-internal-external {
  .switch-ctrl {
    &.lg {
      width: 164px;
      .txt {
        &:before {
          width: auto;
          right: 0;
        }
        &:after {
          content: "Internal";
          width: 80px;
        }
        i {
          width: 80px;
        }
        .sl {
          left: 100px;
        }
      }
      .chk:checked + .txt i {
        left: 80px;
      }
    }
  }
}

.h2-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  padding: 15px 5px;
  letter-spacing: normal;
  color: $black;
  border-bottom: 1px solid $white-grey;
  &.with-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* forms */
input.form-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 32px;
  line-height: 30px;
  padding: 0 8px;
  background-color: $white;
  font-size: 15px;
  font-weight: normal;
  border: 1px solid $very-light-grey;
  color: #262626;
  border-radius: 4px;
  &::-webkit-input-placeholder {
    color: $brownish-grey;
  }
  &::-moz-placeholder {
    color: $brownish-grey;
  }
  &:-ms-input-placeholder {
    color: $brownish-grey;
  }
  &:-moz-placeholder {
    color: $brownish-grey;
  }
  &[type="search"] {
    padding-right: 50px;
    background: $white url("/assets/i/search.svg") right 10px center no-repeat;
    &.top-search {
      width: 200px;
    }
    &::-ms-clear {
      display: none;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
  &:read-only.no-border{
    border:  none;
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    font-weight:500;
  }
}

select.form-input.invalid,
input.form-input.invalid {
  border-color: $bright-red;
}

select.form-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: 1px solid $very-light-grey;
  outline: none;
  width: 100%;
  height: 32px;
  padding: 2px 20px 0 8px;
  background: $white url("/assets/i/chevron-down-dark.svg") right 5px center
    no-repeat;
  background-size: 18px 18px;
  font-size: 15px;
  line-height: 22px;
  font-weight: normal;
  color: $black;
  &::-webkit-input-placeholder {
    color: $brownish-grey;
  }
  &::-moz-placeholder {
    color: $brownish-grey;
  }
  &:-ms-input-placeholder {
    color: $brownish-grey;
  }
  &:-moz-placeholder {
    color: $brownish-grey;
  }
  &:disabled {
    border-color: $brown-grey;
    background-color: $very-light-pink;
    border-radius: 4px;
  }
  &.placeholder {
    color: $brownish-grey;
  }
  &.default-text {
    color: #686868;
    opacity: 0.5;
  }
}

/* row */
.form-row-element {
  display: flex;
}
.form-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-bottom: 10px;
  &:last-child {
    padding-right: 0;
  }
  .label-element {
    padding: 16px 0 5px 0;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: $brownish-grey;
  }
  .error-message {
    display: none;
    color: $errorColor;
    font-size: 12px;
    padding: 5px 0 5px 15px;
    position: relative;
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 5px;
      background: url("/assets/i/error.svg") no-repeat;
      background-size: 12px 12px;
    }
    &.show {
      display: flex;
    }
  }
}

.form-error-message {
  display: none;
  color: $errorColor;
  font-size: 12px;
  padding: 5px 0 5px 15px;
  position: relative;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 5px;
    background: url("/assets/i/error.svg") no-repeat;
    background-size: 12px 12px;
  }

  &.show {
    display: flex;
  }
}



.action-buttons {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
}

/* slider */

.mat-mdc-slider .mdc-slider__thumb-knob {
  height: 16px !important;
  width: 16px !important;
  border-radius: 16px !important;
  border-width : medium !important;
  background-color: white !important;
}

.checkbox-switch {
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: $black;
  position: relative;
  input {
    display: none;
  }
  i {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background-size: 10px 10px;
    background-position: center center;
    background-color: $white;
    border-radius: 100%;
    &.checked {
      left: auto;
      right: 4px;
    }
  }
}

.date-selector {
  display: flex;
  .form-input {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.ngx-datatable:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background: #f4f4f4;
}
@include mobile {
  .nav-tabs .nav-item {
    flex: 1;
    white-space: nowrap;
    .nav-link {
      font-size: 10px !important;
    }
  }
}

@include tablet-and-below {
  .nav-tabs .nav-item {
    flex: 1;
    .nav-link {
      font-size: 12px;
    }
  }
}

/* scrollable panel */
.scroll-panel {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &.small {
    .body-panel {
      height: calc(100% - 65px);
    }
    .footer-panel {
      height: 65px;
    }
  }
  &.small-policy {
    .body-panel {
      height: calc(100% - 25px);
    }
  }

  &.seperate-footer {
    height: calc(100% - 80px);

    .body-panel {
      height: 100%;
    }
  }
  .body-panel {
    position: relative;
    height: calc(100% - 130px);
    overflow: auto;
  }
  &.no-shadow {
    .footer-panel {
      z-index: 1;
      box-shadow: none;
    }
  }
  .footer-panel {
    z-index: 1;
    box-shadow: 0 -5px 34px -5px $lightGray;
    height: 130px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    .footer-buttons {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include mobile {
        justify-content: space-evenly;
        .btn {
          min-width: auto;
        }
      }
      + .footer-buttons {
        border-top: 1px solid $lighterGray;
      }
      .btn + .btn {
        margin-left: 16px;
      }
    }
  }
}

.seperate-footer-panel {
  height: 80px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  .footer-buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mobile {
      justify-content: space-evenly;
      .btn {
        min-width: auto;
      }
    }

    + .footer-buttons {
      border-top: 1px solid $lighterGray;
    }

    .btn + .btn {
      margin-left: 16px;
    }
  }
}
.fix-scroll {
  display: flex;
  flex-direction: column;
  .main-content {
    height: 100%;
  }
}
.ps__rail-y {
  top: 10px !important;
  right: 0 !important;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y {
  opacity: 1;
}

.ps__thumb-y {
  width: 8px;
  border-radius: 6.5px;
  background-color: #999;
}

.ps__thumb-x {
  width: 8px;
  border-radius: 6.5px;
  background-color: #999;
}

.modal-container {
  .modal-header {
    border-bottom: 1px solid $pale-grey;
    height: 55px;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    display: flex;
    .modal-title {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $black;
    }
    button {
      border: 0;
      background: transparent url("/assets/i/close.svg") center center no-repeat;
      width: 14px;
      height: 14px;
      outline: none;
      span {
        display: none;
      }
    }
  }
}


.inner-page-tab {
  .nav.nav-tabs {
    height: 40px;
    display: flex;
    justify-content: normal !important;
    align-items: center;
    border-bottom: 1px solid $lighterGray;
    .nav-item {
      flex: 1;
      .nav-link {
        width: 100%;
        text-align: center;
        border: 0;
        color: $text;
        border-bottom: 4px solid transparent;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        &.active {
          font-weight: bold;
          border-bottom-color: $black;
        }
        &.disabled {
          color: rgba($text, 0.5);
          background: $menu-border;
        }
      }
    }
  }
  .tab-content {
    flex: 1;
    display: flex;
    height: calc(100% - 40px);
    .tab-pane,
    div[role="tabpanel"] {
      width: 100%;
    }
    .tabpanel {
      width: 100%;
      flex: 1;
      display: flex;
      height: 100%;
    }
  }
}

.loading-box:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.pagination {
  padding-top: 16px;
  @include paging-default();
}


.top-tips {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 48px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.35);
  background-color: #2a8b4b;
  z-index: 1001;
  min-width: 520px;
  height: 48px;
  padding: 0 13px;
  .txt {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }
  .icon-done {
    background: url(/assets/i/icon-done@2x.png) center center no-repeat;
    width: 24px;
    height: 24px;
    background-size: 100%;
  }
  .btn-close {
    background: url(/assets/i/icon-close-white@2x.png) center center no-repeat;
    width: 24px;
    height: 24px;
    background-size: 14px;
  }
  &.top-45 {
    top: 45px;
  }
}
/* IE11+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .modal {
    &.fade {
      .modal-dialog {
        &.modal-dialog-centered {
          width: 100vw;
          min-width: 100vw;
          height: 100vh;
        }
      }
    }
  }
}
.recommendation-tooltip {
  width: auto;
  height: 24px;
  background-color: $black;
  color: white;
  font-size: 12px;
}

.barchart-tooltip,
.planner-tooltip,
.task-tooltip {
  .tooltip-inner {
    opacity: 1;
    width: auto;
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba($black, 0.06);
    padding: 8px;
    background-color: $white;
    display: block;
    color: $black;

    h5 {
      font-size: 14px;
      font-weight: 500;
      color: $black;
      text-align: left;
      padding: 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $white-grey;

      span {
        font-size: 12px;
      }
    }

    p {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      margin: 0;
    }
  }

  .arrow::before {
    display: none;
  }
}
.risk-tooltip {
  .tooltip-inner {
    margin-left: 100px;
    opacity: 1;
    width: auto;
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba($black, 0.06);
    padding: 8px;
    background-color: $white;
    display: block;
    color: $black;
    overflow-y: auto;
    max-height: calc(100vh - 238px);
    max-width: 100%;
    // height: 250px;
    // overflow-y: scroll;

    h5 {
      font-size: 14px;
      font-weight: 500;
      color: $black;
      text-align: left;
      padding: 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $white-grey;

      span {
        font-size: 12px;
      }
    }

    p {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      margin: 0;
    }
  }

  .arrow::before {
    display: none;
  }
}

.barchart-tooltip .tooltip-inner {
  min-width: 10px;
  .tooltip-item,
  .tooltip-item p {
    width: 100%;
    text-align: center;
  }
}

/* context menu */
.ngx-contextmenu {
  .dropdown-menu {
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
    background-color: rgba(61, 61, 61, 0.95);
    display: flex;
    padding: 0;
    flex-direction: column;

    li {
      height: 32px;
      text-align: left;
      display: flex;
      align-items: center;
      padding: 0 8px;

      &:hover {
        background-color: $greyish-brown;
      }

      a {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;

        span {
          padding-right: 10px;
        }
      }

      &.divider {
        height: 1px;
        background: $lightBlack;
      }
    }
  }
}

.calendar-wrapper {
  border-radius: 4px;
  .error-message {
    display: none;
    color: $errorColor;
    font-size: 12px;
    padding: 5px 0 5px 15px;
    position: relative;

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 5px;
      background: url("/assets/i/error.svg") no-repeat;
      background-size: 12px 12px;
    }

    &.show {
      display: flex;
    }
  }
  .footer-wrapper {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $lightBlack;
    display: flex;
    height: 20px;
    align-items: center;

    div {
      padding-right: 10px;
      display: flex;
      height: 20px;
      align-items: center;
    }

    i {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin-right: 5px;

      &.outage {
        background-color: $deep-sky-blue;
      }

      &.current {
        box-shadow: 0 4px 12px 0 rgba($black, 0.06);
        border: solid 1px $deep-sky-blue;
        background-color: rgba($deep-sky-blue, 0.1);
      }
    }
  }

  .ngb-datepicker {
    width: 360px;
    border: solid 1px $white-grey;
    background-color: $white;
    .ngb-dp-weekdays {
      border-bottom: 0;
    }
    .ngb-dp-header {
      background-color: $white !important;

      .ngb-datepicker-navigation {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: center;
        color: $text;
        height: 48px;
        border-bottom: solid 1px $white-grey;

        .ngb-datepicker-navigation-select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: center;
          color: $text;
          border: 0;
          pointer-events: none;
          background: none;
        }
        .btn {
          min-width: 10px;
        }
      }
    }

    .ngb-dp-months {
      .ngb-dp-month {
        width: 100%;

        .ngb-dp-week {
          background-color: $white !important;
          display: flex;

          .ngb-dp-weekday {
            border-bottom: solid 1px $white-grey;
            flex: 1;
            height: 48px;
            line-height: 48px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: center;
            color: $text;
          }

          .ngb-dp-day {
            flex: 1;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: center;
            color: $lightBlack;

            .custom-day {
              width: 100%;
              height: 32px;
              line-height: 32px;

              &.selected {
                border-radius: 4px;
                box-shadow: 0 4px 12px 0 rgba($black, 0.06);
                border: solid 1px $deep-sky-blue;
                background-color: $white;
              }

              &.outage {
                position: relative;

                &:after {
                  position: absolute;
                  bottom: 2px;
                  left: 50%;
                  margin-left: -2px;
                  content: "";
                  display: block;
                  width: 4px;
                  height: 4px;
                  border-radius: 4px;
                  background-color: $deep-sky-blue;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ngb-dp-header {
  .btn {
    min-width: initial !important;
  }
}
.ngb-dp-arrow-btn:focus{
  border-color: transparent;
  box-shadow: none;
}
.ngb-dp-navigation-select{
 .form-select{
  &:focus{
    border-color: transparent;
    box-shadow: none;
  }
 }
}

.app-excution-calendar {
  width: 100%;
}

.toast-container {
  padding-top: 80px;
  .ngx-toastr {
    min-width: 624px;
    height: 48px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.35);
    &.multiLine {
      height: 70px;
    }
    &.auto-height {
      height: auto;

      .toast-close-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        right: 10px;
      }
    }
    &.toast-success {
      background-color: #2a8b4b;
      background-image: url("/assets/i/icon-success.svg");
      background-repeat: no-repeat;
      background-position: 10px center;
    }
    &.toast-error {

      background-image: none;

      .toast-message {
        top: 50%
      }
    }
    &.toast-info {
      background-color: #0f00ff;
      background-image: url("/assets/i/icon-toast-info.svg");
      background-repeat: no-repeat;
      background-position: 10px center;
      background-size: 22px 22px;
      .toast-message {
        top: 50%;
        display: flex;
        justify-content: left;
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $white;
        span {
          flex: 1;
          display: flex;
          justify-content: center;
        }
        strong {
          display: flex;
          align-items: center;
          width: 120px;
          height: 32px;
          border-radius: 4px;
          background-color: $text;
          font-weight: normal;
          font-style: normal;
          // font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: center;
          justify-content: center;
          color: $white;
          position: relative;
          margin-top: -5px;
          margin-left: 50px;
          margin-right: 20px;
        }
      }
    }
    .toast-message {
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      top: 50%;
    }
    .toast-close-button {
      background: url("/assets/i/icon-toastr-close.svg") no-repeat;
      background-size: 20px 20px;
      width: 20px;
      height: 20px;
      top: 50%;
      margin-top: -10px;
      span {
        display: none;
      }
    }
  }
}


/* .drop-down */
.drop-down {
  width: 100%;
  position: relative;
  &.width-205 {
    width: 205px;
  }
  .dropdown-btn {
    font-size: 14px;
    color: #003977;
    background: #fff;
    line-height: 30px;
    position: relative;
    display: block;
    padding-left: 10px;
    padding-right: 30px;
    border: 1px solid #bababa;
    text-align: left;
    .value {
      display: table-cell;
      vertical-align: middle;
      height: 30px;
      font-size: 14px;
      color: #282828;
      white-space: nowrap;
    }
  }
  .icon-arrow-down {
    width: 18px;
    height: 16px;
    margin-top: -6px;
    position: absolute;
    right: 10px;
    top: 50%;
    background: url(/assets/i/icon-back.svg) center center no-repeat;
    background-size: 18px auto;
    transform: rotate(-90deg);
  }
  .drop-menu {
    min-width: 50px;
    width: 100%;
    text-align: center;
    padding: 0;
    border: 1px solid #bababa;
    max-height: 400px;
    overflow: auto;
    margin-top: -1px;
    box-shadow: none;
    z-index: 666;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    li {
      display: table;
      width: 100%;
      & > a {
        font-size: 14px;
        color: #282828;
        font-weight: normal;
        text-align: left;
        padding: 8px 12px;
        line-height: 20px;
        height: 30px;
        vertical-align: middle;
        display: table-cell;
        white-space: normal;
        &:hover {
          color: #2b2b2b;
          background: #f8f8f8;
        }
        &.active {
          color: #fff;
          background: #0f6cff;
        }
      }
    }
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

// dragular
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
// .modal-content{
//   width: auto;
// }
.custom-select {
  background: none !important;
  -webkit-appearance: menulist !important;
}

sup {
  color: red;
}

.map-wrapper {
  .markers {
    .accordion-wrapper {
      .accordion {
        .card {
          border: 0;
          border-bottom: 1px solid #dbdbdb;
          .card-body {
            padding: 8px 0;
            ul {
              li {
                border: none !important;
                height: 20px !important;
                .label {
                  font-size: 12px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 1.67;
                  letter-spacing: normal;
                  color: #bababa;
                  width: 25%;
                }
              }
            }
          }
          .card-header {
            border: 0;
            background: #fff;
            padding: 0 8px;
            height: 48px;
            display: flex;
            align-items: center;
            h5 {
              width: 100%;
              text-align: left;
            }
            .btn.btn-link {
              width: 100%;
              padding: 0;
              margin: 0;
              display: flex;
              text-decoration: none;
              justify-content: space-between;
              align-items: center;
              a {
                text-decoration: none;
                background: transparent url("/assets/i/chevron-down-dark.svg")
                  no-repeat;
                background-size: 10px 10px;
                width: 10px;
                height: 10px;
                display: block;
                outline: none;
                border: 0;
              }
              &.collapsed {
                a {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

.accordion-header{
  border: 0;
  background: #fff;
  padding: 0 8px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 0;
  margin-bottom: -1px;
  width: 100%;
  text-align: left;
}
.accordion{
  overflow-anchor: none;
}
.accordion-item{
  border: 0;
  border-bottom: 1px solid #dbdbdb;
}
.accordion-button, .accordion-item:first-of-type .accordion-button, .accordion-button:hover,
.accordion-button.collapsed, .accordion-button:not(.collapsed){
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
}
.accordion-button:focus{
  border-color: transparent;
  box-shadow: none;
}
.accordion-body{
  padding: 8px 0;
  ul {
    li {
      border: none !important;
      height: 20px !important;
      .label {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #bababa;
        width: 25%;
      }
    }
  }
}
.accordion-button:not(.collapsed){
  color: #262626;
  background-color: transparent;
  height: 32px;
  opacity: 0.8;
}
.accordion-button::after{
  text-decoration: none;
  background: transparent url("/assets/i/chevron-down-dark.svg") no-repeat;
  background-size: 10px 10px;
  width: 10px;
  height: 10px;
  display: block;
  outline: none;
  border: 0;
}
.accordion-button:not(.collapsed)::after{
  background: transparent url("/assets/i/chevron-down-dark.svg") no-repeat;
  background-size: 10px 10px;
  transform: rotate(180deg);
}


.custom-tooltip {
  .tooltip-inner {
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    height: 24px;
    line-height: 24px;
    background-color: $greyish-brown;
    color: white;
    font-size: 12px;
    padding: 0 12px;
    white-space: nowrap;
  }

  .arrow:before {
    display: none;
  }
}

.widget-dashboard-fix {
  main {
    max-height: none !important;
  }
  .gridster {
    .gridster-row,
    .gridster-column {
      display: block !important;
    }
    .gridster-row {
      position: static;
      margin-bottom: 10px;
    }
  }
}

.widget-dashboard-fix.full_height {
  .app-edit-widget-dashboard {
    main {
      display: block;
    }
  }
}
.owl-dt-inline-container, .owl-dt-popup-container {
  width: 16.5em !important;
}
.owl-dt-timer-content .owl-dt-timer-input {
  font-size: 15px !important;
}
.owl-dt-inline-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-calendar {
  height: 18.25em !important;
}
.owl-dt-timer {
  height: 5em !important;
  padding: 0px;
}

.app-reliability-center
  .widget-dashboard-fix
  .app-edit-widget-dashboard
  .widget-dashboard-container {
  display: block;
}

.page-body-container.with_scroll {
  overflow: auto;
  > .page-body-content {
    display: block !important;
  }
}
.widget-dashboard-container {
  > h4 {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #262626;
    margin: 0;
    padding: 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
}

.app-edit-combo-widget-modal {
  width: 100%;
  max-width: 85vw;
  .modal-container {
    width: 100%;
  }
}

.app-performance-monitoring-dashboard {
  display: block;
  height: 100%;
  .app-edit-widget-dashboard > .widget-dashboard-container {
    max-height: none !important;
  }
}

.app-report-dashboard {
  .app-edit-widget-dashboard {
    .widget-dashboard-container {
      max-height: none !important;
    }
  }
}

.app-dashboard {
  > .page-container {
    overflow: hidden;
  }
}
// ngb-modal-window {
//   .modal-dialog.modal-dialog-centered {
//     width: 100%;
//     max-width: none;
//     min-width: none;
//     >.modal-content {
//       background-color: transparent;
//       >* {
//         margin-left: auto;
//         margin-right: auto;
//         background-color: #fff;
//       }
//     }
//   }
// }

.xl-modal > .modal-dialog {
  max-width: 1490px !important;
  min-width: 971px !important;
  width: 95% !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.modal-640-632>div {
  height: 632px;
  width: 640px;
}

.modal-640-513>div {
  height: 513px;
  width: 640px;
}

.modal-640-668>div {
  height: 668px;
  width: 640px;
}

.modal-432-694>div {
  height: 694px;
  width: 432px;
}

app-resistance-chart, text {
  font-family: OpenSans, Arial, Helvetica, sans-serif;

}


@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/open-sans/OpenSans-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('./assets/fonts/open-sans/OpenSans-Light.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url('./assets/fonts/open-sans/OpenSans-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: 'OpenSans-Medium';
  src: url('./assets/fonts/open-sans/OpenSans-Medium.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('./assets/fonts/open-sans/OpenSans-Bold.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

// .modal-backdrop {
//   z-index: 1055 !important;
// }

ul {
  padding-left: 0;
}

.ngb-dp-weekday {
  color: black !important;
  font-style: normal !important;
}

::ng-deep .ngx-datatable .datatable-body {
  overflow: hidden;
  overflow-x: hidden;
 }


 // can be used to hide popper glitches
@keyframes fadeInDelay50 {
  0% {
    opacity: 0; /* Start completely transparent */
  }
  50% {
    opacity: 0; /* Still transparent at the halfway point */
  }
  100% {
    opacity: 1;
  }
}

.radio-option {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: auto;
  min-width: 57px;
  margin-bottom: 8px;
  padding: 7px 12px;
  line-height: 19px;
  border: 1px solid #414141;
  border-radius: 2px;
  cursor: pointer;

  &.disabled {
    opacity: 0.7;
  }

  &.checked {
      background:#414141 0% 0% no-repeat padding-box;
      color: #FFFFFF;
  }

  .radio {
      height: 12px;
      width: 12px;
      margin-right: 8px;
      cursor: pointer;
      accent-color: #FBFCFC;
  }

  .radio-label {
      line-height: 18px;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.02px;
  }
}

.input-ctrl, .form-input .radio{
  &:disabled {
    background-color: #fff;
    opacity: 0.4;
    pointer-events: none;
  }
}

