/* normalize css */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 100%;
}
body{
  color: #262626;
  background: #f4f4f4;
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  line-height: 1;
  font-size: 16px;
}
/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
main,
nav,
section {
  display: block;
}


a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
a:visited,
a:focus {
  outline: none;
}
a{
  transition: 0.25s;

  &:hover{
    opacity: 0.7;
  }
}
a,span,i{
  display: inline-block;
}
h1,h2,h3,h4,h5{
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
i,em{
  font-style: italic;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
  font-weight: normal;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
strong{
  font-weight: 500;
}
input,
textarea {
  font-family: OpenSans,Helvetica, Arial, Verdana, Tahoma, sans-serif;
  outline: none;
  box-shadow: none;
  resize: none;
}
input::-webkit-input-placeholder {
  color: rgba(40, 40, 40, 0.5);
}
input::-moz-input-placeholder {
  color: rgba(40, 40, 40, 0.5);
}
input:-ms-input-placeholder {
  color: rgba(40, 40, 40, 0.5);
}

textarea::-webkit-input-placeholder {
  color: rgba(40, 40, 40, 0.5);
}
textarea::-moz-input-placeholder {
  color: rgba(40, 40, 40, 0.5);
}
textarea:-ms-input-placeholder {
  color: rgba(40, 40, 40, 0.5);
}
textarea{
  overflow: auto;
}
svg:not(:root) {
  overflow: hidden;
}
button{
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  outline: none;
  transition: 0.25s;

  &:hover{
    opacity: 0.7;
  }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="search"]::-ms-clear {
  display: none;
}
input::-ms-clear {
  display: none;
}

select {
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

