/** Set all scss variables in here, use them in any scss file via `@import 'variables';` */
$tablet-width: 768px;
$desktop-width: 1224px;
$mobile-width: 320px;

$gray: #979797;
$lightGray: #DBDBDB;
$lighterGray: #F4F4F4;
$alabaster: #fafafa;
$bgColor: #F8F8F8;
$activeOpacity: 0.26;
$lowRisk: #14BE6E;
$mediumRisk: #FFF050;
$highRisk: #FF5A3C;
$black: black;
$errorColor: #FF5A3C;
$text: #262626;
$errorColor: #FF5A3C;
$lightBlack : #686868;
$white: #fff;
$mediumGray: #ababab;
$darkMediumGray: #bababa;
$silverChalice: #a7a7a7;
$boulder: #7a7a7a;
$doveGray: #6e6e6e;
$blueBayoux: #55637d;

$white: #ffffff;
$brownish-grey: #686868;
$very-light-grey: #bababa;
$very-light-pink: #dbdbdb;
$brown-grey: #9f9f9f;
$lt-greyish-brown: #525252;
$white-grey: #f4f4f4;
// $greyish-brown: #525252;
$white-grey: #f4f4f4;
$brown-light-grey: #a7a7a7;
$bright-red: #ff000f;
$black: #262626;
$pale-grey: #d8d8d8;
$greyish-brown: #3d3d3d;
$pale-lilac: #dad7ff;
$light-periwinkle: #b7b2ff;
$cornflower: #7a70ff;
$purpley-blue: #382ce6;
$purpleish-blue: #5548ff;
$indigo-blue: #2319bd;
$periwinkle-blue: #9992ff;
$royal: #170f87;
$midnight-blue: #050047;
$strawberry: #f03040;
$electric-blue: #0f50ff;
$true-blue: #0f00d0;
$primary-blue: #0f00ff;
$deep-blue: #0f0089;
$deep-sky-blue: #0f6cff;
$slider-bg: #bfbfbf;
$slider-text: #6e6e6e;
$slider-border: #b4b4b4;
$scroll-bar: #101010;
$menu-border: #f2f2f2;
$menu-disabled: #848484;
$checkbox-color: #464646;
$menu-disabled-bg: $menu-border;
$slider-green: #329a5d;
$outline-blue: #4a90e2;


// planning
$short: #10c4ac;
$medium: #6c61ff;
$long: #e31e71;
$custom: #050047;
$row-alt: #dfdfdf;
